import * as React from "react";
import { Tween } from "react-gsap";
import "./styles.css";

function Logo() {
  return (
    <svg
      id="logo"
      viewBox="0 0 600 90"
      style={{ width: "300px", maxWidth: "75vw" }}
    >
      <Tween
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        stagger={0.1}
        duration={0.05}
      >
        <path
          id="m1Path"
          className="st0 path"
          d="M35.33,63.04c-1.19-1.29-1.46-3.82-0.96-5.64L40,36.08c0.18-2.48,1.3-5.66,2.39-5.41
c0.98-0.13,2.77,0.33,2.77,0.33s-3.48,13.2-5.65,21.53c-0.84,3.41,0.34,7.67,0.29,8.75s-0.91,2.28-1.88,2.41
C36.76,63.76,35.79,63.66,35.33,63.04z"
        />
        <path
          id="m2Path"
          className="st0 path"
          d="M44.21,30.79c1.39,0.12,2.47,1.03,3.19,2.81l1.25,4.68c2.13,6.2,4.92,11.44,5.97,12.39
	s3.02,2.72,3.02,2.72c-0.71,1.22-2.3,2.75-3.02,2.55c-0.96,0.02-2.43-1.02-3.07-2.06c-1.84-3.1-3.42-6-4.49-8.55
  c-1.34-4.2-2.39-7.39-3.16-9.62l-0.83-2.35C42.9,32.15,43.17,31.21,44.21,30.79z"
        />
        <path
          id="m3Path"
          className="st0 path"
          d="M54.62,50.68c0.77-0.32,1.54-1.06,2.32-2.26c0.73-1.18,1.53-2.49,2.44-4
	c0.31-0.52,0.67-1.01,1.08-1.46c2.46-2.78,5.3-6.4,8.33-10.43c1.18-1.84,2.37-3.46,3.92-5.67c0.59-0.29,3.4,0.76,3.4,1.45
  c0.2,0.57-0.23,1.12-0.7,1.66l-5.04,5.98c-4.47,5.8-8.69,11.63-12.73,17.46l-1.27,1.58c-2.59,2.42-6.04-1.6-3.05-3.35L54.62,50.68z"
        />
        <path
          id="m4Path"
          className="st0 path"
          d="M75.41,29.96l-0.78,2.65l-0.26,3.79l-1.12,11.93c-0.25,4.74-0.28,9.18,0,13.19
	c0.17,0.61,0.79,1.07,1.78,1.42c1.14,1.25-0.79,3.51-2.45,3.51c-1.11-0.1-2.05-0.67-2.76-1.89c-1.11-2.99-1.35-6.03-1.25-9.1
  c0.78-5.6,1.48-11.81,2.05-18.91l-0.23-0.61l0.03-4.51C70.54,28.2,75.41,27.24,75.41,29.96z"
        />
        <path
          id="a1Path"
          className="st0"
          d="M101.38,44.33c-0.26-1.2-0.69-2.31-1.59-3.19c-1.45-0.75-3.23-0.78-5.12-0.6
	c-6.63,2.13-13.1,10.12-13.5,18.59c0,2.57,2.01,5.11,4.25,4.93c4.44-1,8.28-3.85,11.71-7.95c3.52-3.31,0.7-6.77-1.97-1.88
	c-2.42,3.26-7.12,6.33-9.25,5.98c-0.91-0.21-1.3-1.27-1.08-3.36c2.65-8.25,7.37-13.7,11.97-12.96c0.62,0.4,0.76,1.17,0.73,2.08
	c-0.01,0.16-0.02,0.32-0.03,0.49C97.59,49.1,100.85,47.71,101.38,44.33z"
        />
        <path
          id="a2Path"
          className="st0"
          d="M97.49,43.61c0.44-2.25,1.96-1.99,3.41-0.53c0.31,0.31,0.51,0.73,0.52,1.16
	c0.08,4.46-3.19,8.21,0.56,11.68c0.72,0.67,2.14,1.08,3.1,0.87c3.16-0.69,7.82-4.29,11.38-8.37c0.23-0.26,0.37-0.58,0.41-0.93
	c0.24-1.76,0.52-3.4,1.04-4.5c0.18-0.39,0.5-0.7,0.89-0.86c0.87-0.37,1.6-0.22,2.14,0.63c0.58,1.51,0.08,3.2-0.59,5.35
	c-0.17,0.55-0.49,1.04-0.92,1.42c-4.14,3.66-8.21,7.64-11.57,9.77c-0.09,0.06-0.19,0.11-0.3,0.16c-5.09,2.14-8.79,1.55-10.39-3.2
	c-0.03-0.09-0.05-0.17-0.07-0.26C96.67,53.94,97.64,49.59,97.49,43.61z"
        />
        <path
          id="r1Path"
          className="st0"
          d="M117.35,46.49c0.56-2.9,3.81-2.78,3.47,0c-2.23,9.2-2.46,15.14-0.83,18.05
	c-0.07,0.65-2.44,1.34-3.03,1.01C114.68,64.07,115.74,53.98,117.35,46.49z"
        />
        <path
          id="r2Path"
          className="st0"
          d="M119.99,64.54c-1.74,1.47-3.7,0.14-2.87-2.58c5.41-13.98,11.84-21.89,20.34-17.44
	c0.43,0.53,0.19,2.14-0.46,2.45C128.79,45.57,123.02,52.26,119.99,64.54z"
        />
        <path
          id="i1Path"
          className="st0"
          d="M141.65,44.36c-1.84,9.49-1.16,15.77,2.57,19.37C155,68.81,167.66,42.47,174.9,44
	c4.59,0.19,3.17-3.5-0.84-3.34c-3.58,0.39-7.16,3.01-11.58,9.1c-3.63,5.01-10.12,9.96-14.64,10.71c-3.6,0.18-3.56-8.39-2.15-16.96
	C145.9,41.25,142.64,42.41,141.65,44.36z"
        />
        <circle
          id="iDot1Path"
          className="st0"
          cx="146.86"
          cy="30.84"
          r="2.87"
        />
        <path
          id="a3Path"
          className="st0"
          d="M167.92,46.99c0.59-1.9-1.09-2.64-2.86-0.51c-2.29,2.87-4.05,6.16-5.26,9.86
	c-0.58,2.42,0.22,6.75,2.62,7.69c5.3,0.59,10.82-4.65,15.92-11.51c0.62-1.77-2.34-3.52-3.17-1.54c-3.03,6.53-8.84,10.19-11.08,9.43
	S163.84,53.29,167.92,46.99z"
        />
        <path
          id="a4Path"
          className="st0"
          d="M174.05,40.96c1.01-0.53,2.45-0.42,4.37,0.4c0.96,1.03,1.33,2.07,1.35,3.13
	c-3.81,14.28,2.25,17.18,14.8,4.37c3.51-3.41,4.07,0.25,1.33,2.46c-11.31,12-23.21,14.57-20.02-5c0.07-1.11-0.11-1.92-0.65-2.3
	l-1.48-0.61L174.05,40.96z"
        />
        <path
          id="n1Path"
          className="st0"
          d="M196.86,42.24c0,0,2.39-1.55,2.59,0.38s-2.39,9.36-1.89,12.29c1.78-1.15,5.94-7.89,10.3-10.95
	c2.06-1.05,3.88-0.9,5.49,0.35c2.03,2.27,1.43,12.21,3.86,13.01c4.72,0.88,9.78-3.56,14.95-9.95c3.01-3.73,6.23-6.15,9.77-6.7h2.42
	c3.21,0.78,2.23,4.53-0.95,3.7c-2.89-1.36-6.06,1.33-9.34,5.64c-3.85,4.89-8.76,8.54-14.45,11.28c-3.13,0.52-4.81,0.43-6.51-3.11
	s-1.17-9.65-3.71-10.46c-3.27-1.05-8.96,12.27-13.55,13.71c-2.08-0.17-2.45-3.38-2-8.04C194.64,49.03,195.49,44.83,196.86,42.24z"
        />
        <path
          id="a5Path_1_"
          className="st0"
          d="M229.92,50.43c1.76-2.48,4.9-2.52,3.51,0.36c-1.91,3.84-2.9,6.69-2.25,8.61
	c0.18,0.54,0.72,0.91,1.29,0.85c4.61-0.53,7.83-3.47,10-8.26c0.48-1.05,1.86-1.29,2.68-0.47l0.03,0.03
	c0.58,0.58,0.63,1.49,0.13,2.15c-3.87,5.12-8.9,9.77-13.43,10.43c-0.61,0.09-1.24-0.04-1.76-0.37
	C225.9,61.09,227.75,55.71,229.92,50.43z"
        />
        <path
          id="a6Path"
          className="st0"
          d="M243.26,43.57l1.36-1.88c0.26-0.36,0.72-0.56,1.15-0.42c0.63,0.21,1.2,0.86,1.69,2.1
	c0.24,0.61,0.25,1.31,0.07,1.94c-5,17.39,7.5,12.63,16.15,2.33c0.2-0.24,0.6-0.31,0.8-0.08c0.4,0.45,0.08,1.56-0.43,2.83
	c-0.08,0.2-0.2,0.39-0.35,0.55c-8.89,9.57-16.16,13.22-20.29,5.31c-2.14-5.26,1.88-8.6-0.15-11.85
	C243.09,44.15,243.08,43.82,243.26,43.57z"
        />
        <path
          id="c1Path"
          className="st0"
          d="M302.73,37.43c-1.13,2.9,1.57,3.51,3.46,2.53c1.72-2.4,3.2-4.7,3.3-6.46
    c-1.28-3.3-2.97-4.88-6.75-4.48c-12.57,3.76-21.81,12.1-23.06,27.01c0.2,8.36,4.86,11.55,13.79,9.78c10.04-3,19.09-9.4,27.73-17.19
    c2.5-2.15,0.83-4.27-1.68-2.72c-10.1,8.75-18.96,14.52-26.18,16.35c-6.92,1.47-10.53-0.81-9.45-8.39
    c0.82-11.92,15.78-23.46,21.88-20.33L302.73,37.43z"
        />
        <path
          id="a7Path"
          className="st0"
          d="M333.14,42.72c-2.29-2.66-5.6-2.71-9.97-0.11c-6.62,4.88-8.81,10.31-9.56,15.93
    c0,5.51,3.13,6.71,8.19,4.27c3.86-2.42,6.96-5.39,10.01-9.4c0.17-0.23,0.12-0.56-0.12-0.72l-1.37-0.92
    c-0.58-0.39-1.36-0.15-1.63,0.49c-1.66,3.84-4.51,5.99-7.75,7.44c-2.9,1.39-4.59,1.05-3.74-2.88c1.54-5.17,4.16-9.22,7.91-12.12
    c2.28-1.11,3.77-1.27,4.36-0.34C331.04,46.18,333.91,46.38,333.14,42.72z"
        />
        <path
          id="a8Path"
          className="st0"
          d="M330.62,42.83l1.99-0.09c0.36-0.02,0.69,0.2,0.82,0.54c1.07,2.93-2.76,8.13-0.07,12.07
    c2.75,3.55,8.56,0.75,15.89-7.33c0.47-0.52,1.29-0.54,1.78-0.04l0,0c0.41,0.42,0.43,1.09,0.04,1.53l-3.56,4.09
    c-0.04,0.05-0.09,0.1-0.13,0.14c-8.77,8.38-15.19,9.01-17.96,2.6c-1.51-4.87,1.29-7.41,0.19-12.11
    C329.43,43.54,329.91,42.86,330.62,42.83z"
        />
        <path
          id="v1Path"
          className="st0"
          d="M349.54,43.56c-3.12,4.8,4.74,15.27,5.1,21.37c0.04,0.77,0.46,1.51,1.18,1.75
    c0.91,0.31,2.03-0.07,2.65-1.14c2.83-4.99,5.47-10.46,7.95-16.34l1.22-2.76c0.16-0.37,0.06-0.82-0.28-1.04
    c-0.46-0.31-1.11-0.46-1.73-0.36c-0.59,0.09-1.16,0.42-1.51,1.05c-2.23,5.93-4.45,11.51-6.61,14.93
    c-2.45-4.12-4.51-10.08-4.64-12.82c-0.02-0.33,0.03-0.65,0.11-0.96c0.26-0.98,0.44-1.93,0.43-2.77c0-0.17-0.04-0.34-0.13-0.49
    C352.4,42.36,350.58,42.21,349.54,43.56z"
        />
        <path
          id="v2Path"
          className="st0"
          d="M365.95,45.95l0.12-0.26c0.21-0.46,0.81-0.6,1.19-0.27c3.5,2.93,7.04,2.54,11.2,0.51
    c0.34-0.17,0.65-0.4,0.93-0.66c2.42-2.32,4.93-3.99,7.57-4.61c1.19-0.12,2.23-0.12,3.06,0.01c0.9,0.15,1.7,0.69,2.21,1.45
    c0.22,0.32,0.4,0.65,0.56,0.97c0.37,0.77-0.06,1.68-0.89,1.86l-1.04,0.23c-0.77,0.17-1.51-0.18-2.04-0.77
    c-0.14-0.15-0.31-0.29-0.52-0.41c-3.66-1.75-10.62,9.23-20.62,5.97C366.02,49.43,365.2,47.54,365.95,45.95z"
        />
        <path
          id="a9Path"
          className="st0"
          d="M383.42,43.56L383.42,43.56c-0.62-0.29-1.36-0.21-1.89,0.22c-4.43,3.55-7.25,7.73-8.33,12.59
    c-0.06,0.25-0.09,0.51-0.1,0.76c-0.19,3.92,0.9,6.43,3.72,7c0.32,0.06,0.65,0.07,0.97,0.01c3.17-0.63,8.08-4.58,11.15-7.87
    l2.19-3.09c0.35-0.49,0.14-1.18-0.42-1.4l-0.46-0.18c-0.93-0.36-1.99,0.02-2.48,0.9c-2.59,4.64-6.03,7.66-9.67,7.86
    c-0.25,0.01-0.52-0.04-0.72-0.2c-2.51-1.99,0.87-9.46,6.35-14.81C384.28,44.81,384.12,43.89,383.42,43.56z"
        />
        <path
          id="a10Path"
          className="st0"
          d="M390,43.56c-0.57,0.03-0.97,0.57-0.86,1.13c1.01,4.98-2.4,7.07-0.27,11.86
    c3.08,6.27,8.69,5.41,16.42-1.45c5.06-5.25,10.72-11.38,13.02-12.66l1.52,0.22c0.37,0.05,0.75,0.02,1.11-0.1l1.88-0.62
    c0.81-0.27,1.01-1.36,0.31-1.85c-1.35-0.96-3.35-1.1-5.24-0.42c-3.65,1.64-6.63,4.73-9.29,8.5c-6.7,7.23-13.37,12.09-16.39,6.01
    c-1.11-4.52,0.7-7.03,0.95-9.43c0.07-0.72-0.55-1.33-1.27-1.29L390,43.56z"
        />
        <path
          id="g1Path"
          className="st0"
          d="M413.24,44.31l0.02,0.01c0.68,0.35,0.88,1.24,0.41,1.84c-2.65,3.45-4.47,6.84-4.72,10.1
    c-0.01,3.71,10.71-1.47,11.49-5.99c0,0,1.3-1.74,2.32-0.88c0.45,0.38,0.66,1.05,0.75,1.68c0.12,0.85-0.25,1.7-0.95,2.2
    c-3.42,2.44-9.31,8.24-14.05,8.59c-0.95,0.07-1.88-0.3-2.48-1.04c-1.1-1.34-1.29-3.23-0.64-5.61c1.36-4.17,3.56-7.7,6.47-10.69
    C412.21,44.15,412.78,44.07,413.24,44.31z"
        />
        <path
          id="g2Path"
          className="st0"
          d="M421.26,40.94c-0.69,0.48-0.95,1.3-0.62,1.97c0.14,0.29,0.22,0.64,0.26,1.05
    c-0.69,8.11-1.48,22.7-3.81,26.16c-2.52,4.1-9.68,1.95-14.77-2.77c-0.49-0.4-0.87,0.38-0.68,0.96c3.95,7.15,12.94,10.52,18.06,3.7
    c2.79-4.84,3.94-16.83,4.74-29.52c0.03-0.53-0.09-1.06-0.39-1.5c-0.01-0.02-0.02-0.03-0.03-0.05c-0.49-0.72-1.49-0.88-2.21-0.38
    L421.26,40.94z"
        />
        <path
          id="n2Path"
          className="st0"
          d="M433.68,42.24c-3.29,6.74-4.3,18.23-1.01,19.18c3.68-0.95,10.68-14.65,13.55-13.71
    c3.46,0.08,0.96,13.23,7.73,13.71c5.18,0.92,13.74-7.68,20.16-15.13c0.68-0.79,0.58-1.97-0.22-2.64v0
    c-0.59-0.49-1.47-0.41-1.97,0.17c-5.03,5.92-11.47,14.45-16.97,13.75c-4.92-0.69-1.01-14.04-7.39-14.17
    c-5.05-0.79-9.4,8.67-12.59,11.12c-0.27,0.21-0.66,0.02-0.69-0.31c-0.39-4.03,2.9-10.16,1.76-12.06
    C435.53,41.29,434.3,41.31,433.68,42.24z"
        />
        <path
          id="o1Path"
          className="st0"
          d="M482.76,43.81c-1.6-2.77-3.75-4.61-6.82-3.07c-4.63,2.61-8.11,7.22-10.75,13.8
    c-0.52,1.28-0.69,2.68-0.46,4.05c0.4,2.41,1.67,4.29,4.74,5.09c3.11,0.47,7.85-1.08,10.77-3.84c0.73-0.69,0.72-1.86-0.02-2.54v0
    c-0.66-0.6-1.66-0.55-2.29,0.08c-1.92,1.93-4.2,2.95-7,2.7c-1.32-0.32-2.07-1.14-2.37-2.34c-0.23-0.92-0.12-1.9,0.21-2.79
    c1.61-4.34,4.05-7.81,7.25-10.49c1.65-0.97,3.17-0.38,4.6,1.24C482.36,47.86,484.81,47.2,482.76,43.81z"
        />
        <path
          id="o2Path"
          className="st0"
          d="M481.07,43.98L481.07,43.98c0.63-0.35,1.43-0.19,1.89,0.37c3.8,4.63,2.58,9.51-1.73,14.56
    c-0.67,0.78-1.8,1.01-2.72,0.55l0,0c-0.87-0.44-1.06-1.61-0.35-2.28c3.79-3.56,4.87-7,2.44-11.63
    C480.31,44.98,480.52,44.29,481.07,43.98z"
        />
        <path
          id="o3Path"
          className="st0"
          d="M477.41,41.83l-0.27,0.42c-0.46,0.72-0.25,1.67,0.47,2.13c5.93,3.76,13.7,5.66,20.59,3.04
    c0.71-0.27,1.23-0.9,1.35-1.65v0c0.12-0.77-0.62-1.39-1.36-1.14c-6.44,2.17-12.44,0.87-19.22-3.15
    C478.43,41.16,477.74,41.31,477.41,41.83z"
        />
        <path
          id="l1Path"
          className="st0"
          d="M502.73,29.75c0.16-0.73-0.2-1.5-0.88-1.79c-1.29-0.55-2.67,0.36-3.16,1.95
    c-1.43,10.27-4.65,22.6-2.37,28.78c5.74,9.58,15.29,2.56,22.71-9.01c0.63-0.99,0.75-2.23,0.28-3.31l0,0
    c-9.4,12.86-17.85,18.15-20.03,8.41C499.07,48.71,500.4,40.06,502.73,29.75z"
        />
        <path
          id="l2Path"
          className="st0"
          d="M523.99,29.75c0.16-0.73-0.2-1.5-0.88-1.79c-1.29-0.55-2.67,0.36-3.16,1.95
    c-1.43,10.27-4.65,22.6-2.37,28.78c5.74,9.58,15.29,2.56,22.71-9.01c0.63-0.99,0.75-2.23,0.28-3.31l0,0
    c-9.4,12.86-17.85,18.15-20.03,8.41C520.33,48.71,521.66,40.06,523.99,29.75z"
        />
        <path
          id="i2Path"
          className="st0"
          d="M541.66,42.58c-0.88,0.31-1.6,0.93-2.23,1.72c-0.39,0.49-0.63,1.09-0.72,1.7
    c-1.16,7.98-1.34,13.51,3.03,17.22c0.84,0.71,1.95,1.05,3.03,0.92c5.95-0.7,12.07-6.1,17.1-13.77c0.42-0.64,0.49-1.45,0.2-2.16
    l-0.24-0.58c-5.53,6.73-12.19,12.09-16.01,12.83c-0.78,0.15-1.61-0.14-2.1-0.76c-3.21-4.08-0.92-13.01-0.77-16.2
    C542.99,42.82,542.31,42.35,541.66,42.58z"
        />
        <circle
          id="iDot2Path"
          className="st0"
          cx="543.71"
          cy="30.67"
          r="2.87"
        />
      </Tween>
    </svg>
  );
}

export default Logo;
